import React, { Component } from 'react';
import '../styleSheets/Users.scss';
import Dashboard from './Dashboard';
import Header from '../Components/Header';
import UsersTable from '../Components/UsersTable';
class Users extends React.Component {
    constructor(props) {
        super(props);        
    }
    render() {
        return (
            <main>
                <Header />
                <UsersTable />
            </main>
            
        );
        
    }
}
export default Users;
