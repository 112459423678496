import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import '../styleSheets/Header.scss';
import {Link} from 'react-router-dom';
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {number: 0};
      }
    render() {
        return (
            <Nav fill variant="tabs" defaultActiveKey="/dashboard">
                <Nav.Item>
                    <Nav.Link><Link to={'/users'}>Liste des utlisateurs</Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link ><Link to={'/'}>Logout</Link></Nav.Link>
                </Nav.Item>
            </Nav>
        );
        
    }
}
export default Header;
