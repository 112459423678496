import React, { Component } from 'react';
import MainRouter from './Routing/MainRouter';

class App extends React.Component {
   constructor(props) {
      super(props);
      
    }
   render() {
      return (
       <MainRouter/>
      );
   }
}
export default App;
