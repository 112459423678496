import * as firebase from 'firebase';

var config = {
    apiKey: "AIzaSyBGvxvRJJz0nCmeNGyB5jQFZI4xMFUqBJE",
    authDomain: "danaoproject-252d0.firebaseapp.com",
    databaseURL: "https://danaoproject-252d0.firebaseio.com",
    projectId: "danaoproject-252d0",
    storageBucket: "danaoproject-252d0.appspot.com",
    messagingSenderId: "271905674758"
  };
firebase.initializeApp(config);


export default firebase;