import React, { Component } from 'react';
import firebase from '../firebase';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container';
class UsersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users:[],
            old:[],
            city: "",
            number:0
        }        
    }
    componentDidMount(){
        const itemsRef = firebase.database().ref('users');
        itemsRef.on('value', (snapshot) => {
            let items = snapshot.val();
            let newState = [];
            let counter = 0;
            for (let item in items) {
                let images = [];
                for (let image in items[item].Images) {
                    images.push({
                        src:items[item].Images[image].ImageUrl
                    });
                }
                newState.push({
                    id:counter,
                    city:items[item].city,
                    name:items[item].name,
                    images:images
                });
                counter++;
            }
            
            this.setState({
                users: newState,
                old: newState,
                number:newState.length
            });
        });
    }
    onChange(value){
        if( value == 0 ){
            this.setState({
                city: value ,
                old : this.state.users,
                number:this.state.users.length
            })
        }else{
            this.setState({
                city: value ,
                old : this.state.users.filter(item => item.city === value),
                number:this.state.users.filter(item => item.city === value).length
            })
        }
       
    }
    render() {
        return (
            <div>
            <select name="city" onChange={e => this.onChange(e.target.value)}>
                <option value="0" >Tout les gouvernerats </option>
                <option value="Tunis">Tunis </option>
                <option value="Ariana">Ariana </option>
                <option value="La Mannouba">La Mannouba </option>
                <option value="Ben Arous">Ben Arous </option>
                <option value="Beja">Beja</option>
                <option value="Jendouba">Jendouba </option>
                <option value="Bizerte">Bizerte </option>
                <option value="Kef">Kef </option>
                <option value="Sousse">Sousse </option>
                <option value="Sfax">Sfax </option>
                <option value="Monastir">Monastir </option>
                <option value="Siliana">Siliana </option>
                <option value="Kasserine">Kasserine </option>
                <option value="Sidi Bouzid">Sidi Bouzid </option>
                <option value="Gafsa">Gafsa </option>
                <option value="Tozeur">Tozeur </option>
                <option value="Kébili">Kébili </option>
                <option value="Tataouine">Tataouine </option>
                <option value="Médenine">Médenine </option>
                <option value="Gabès">Gabès </option>
                <option value="Kairouan">Kairouan </option>
                <option value="Mahdia">Mahdia </option>
                <option value="Zaghouan">Zaghouan </option>
                <option value="Nabeul">Nabeul </option>
            </select>
            <Container className="dashboardContainer" fluid="true">
                <Row>
                    <Col></Col>
                    <Col></Col>
                    
                    <Col>
                        <div className="card-item">
                            <div className="card-content">
                                <h1 className="title">{this.state.number}</h1>
                                <p>Utlisateurs</p>
                            </div>
                        </div>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </Row>
            </Container>
            <Table responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>name</th>
                        <th>city</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.old.map((item) => {
                        return (
                            <tr key={item.id} data-city={item.city}>
                                <td>{item.id}</td>
                                <td>
                                   <span>{item.name}</span>   
                                   <Row>
                                   
                                   {item.images.map((image) => {
                                       return(
                                        
                                        <Col>
                                            <Image src={image.src} />
                                        </Col>
                                       )
                                      
                                       
                                    })}
                                    <Col></Col>
                                    <Col></Col>
                                    <Col></Col>
                                    </Row>  
                                </td>
                                <td>{item.city}</td>
                        </tr>
                        )
                    })}
                </tbody>
            </Table>
            </div>
        );
        
    }
}
export default UsersTable;
