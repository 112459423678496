import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import '../styleSheets/login.scss';
import firebase from '../firebase';
class Login extends React.Component {
  constructor(props) {
    super(props)
  }
  onSubmit(e){
    e.preventDefault();
    console.log(this.state.email)
    const itemsRef = firebase.database().ref('admin');
    itemsRef.on('value', (snapshot) => {
        let item = snapshot.val();
        if( item.email == this.state.email && item.password == this.state.password ){
          this.props.history.push('/users');
        }else{
          alert('mot de passe ou email incorrect')
        }
    });
   
    
  }

  handleEmailChange(e) {
    this.setState({email: e.target.value});
 }
 handlePasswordChange(e) {
   console.log(e.target.value)
    this.setState({password: e.target.value});
 }
  render() {
      return (
          <div>
              <Form onSubmit={this.onSubmit.bind(this)}>
                <h5>Authentification</h5>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control type="email" required placeholder="Email" onChange={e => this.handleEmailChange(e)}/>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Control type="password" required placeholder="Password" onChange={e => this.handlePasswordChange(e)} />
                </Form.Group>
                <Button variant="primary" type="submit" >
                  Submit
                </Button>
              </Form>
          </div>
      )
  }
}

export default Login;
