import React from 'react'
import { Switch, Route } from 'react-router-dom';
import Login from '../Pages/Login';
import Users from '../Pages/Users';

const MainRouter = () => (
    <Switch>
      <Route exact path='/' component={Login}/>
      <Route path='/users' component={Users}/>
    </Switch>
)

export default MainRouter
