import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../styleSheets/dashboard.scss';
import firebase from '../firebase';
import { runInThisContext } from 'vm';
class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        const itemsRef = firebase.database().ref('users')
        this.state = {
            counter:0
        }
        itemsRef.on('value', (snapshot) => {
            let items = snapshot.val();
            let counter = 0;
            for (let item in items) {
                counter++;
            }
            this.setState({
                counter: counter
            });
            console.log(this.state.users)
        });
    }
    render() {
        return (
            <Container className="dashboardContainer" fluid="true">
                <Row>
                    <Col></Col>
                    <Col></Col>
                    
                    <Col>
                        <div className="card-item">
                            <div className="card-content">
                                <h1 className="title">{this.state.counter}</h1>
                                <p>Utlisateurs</p>
                            </div>
                        </div>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </Row>
            </Container>
        );
        
    }
}
export default Dashboard;
